import { roles } from '@/enums'

export default defineNuxtRouteMiddleware((to, _from) => {
  const user = useSupabaseUser()
  // @todo check for right school id
  const isStudent = user.value?.user_metadata.schoolRoles?.find(
    (schoolRole: SchoolRole) => schoolRole.role === roles.STUDENT,
  )
  if (!isStudent) {
    return navigateTo('/')
  }
})
